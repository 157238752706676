import React from "react"

const PrivacyPolicyContent = () => (
  <section className="privacy-policy-section">
    <h1 className="privacy-policy-section-title">Privacy and Cookie policy</h1>
    <ol>
      <li>
        <h1>Introduction</h1>
        <ol>
          <li>
            This policy applies where we are acting as a data controller with
            respect to the personal data of our website visitors; in other words
            where we determine the purposes and means of the processing of that
            personal data.
          </li>
          <li>
            We use cookies on our website. Insofar as those cookies are not
            strictly necessary for the provision of our website, we will ask you
            to consent to our use of cookies when you first visit our website.
          </li>
          <li>
            In this policy, "we", "us" and "our" refer to Magnus Monitors.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="2">
      <li>
        <h1> Credit</h1>
        <ol>
          <li>
            This document was created using a template from SEQ Legal
            (<a href="https://seqlegal.com/free-legal-documents/privacy-policy">https://seqlegal.com/free-legal-documents/privacy-policy</a>).
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="3">
      <li>
        <h1> How we use your personal data</h1>
        <ol>
          <li>
            In Section 3, we have set out:
            <ol>
              <li>
                The general categories of personal data that we may process;
              </li>
              <li>The purposes for which we may process personal data; and</li>
              <li>The legal bases of the processing.</li>
            </ol>
          </li>
          <li>
            We may process data about your use of our website and services
            ("usage data"). The usage data may include your IP address,
            geographical location, browser type, and version, operating system,
            referral source, length of visit, page views and website navigation
            paths, as well as information about the timing, frequency, and
            pattern of your service use. The source of the usage data is Google
            Analytics. This usage data may be processed for the purposes of
            analyzing the use of the website and services.
          </li>
          <li>
            We may process information contained in any enquiry you submit to us
            regarding goods and/or services ("enquiry data"). The enquiry data
            may be processed for the purposes of offering, marketing, and
            selling relevant goods and/or services to you.
          </li>
          <li>
            We may process information relating to our customer relationships,
            including customer contact information ("customer relationship
            data"). The customer relationship data may include your name, your
            employer, your job title or role, your contact details, and
            information contained in communications between us and you or your
            employer. The customer relationship data may be processed for the
            purposes of managing our relationships with customers, communicating
            with customers, keeping records of those communications, and
            promoting our products and services to customers.
          </li>
          <li>
            We may process information relating to transactions, including
            purchases of goods and services, that you enter into with us and/or
            through our website ("transaction data"). The transaction data may
            include your contact details, your card details, and the transaction
            details. The transaction data may be processed for the purpose of
            supplying the purchased goods and services and keeping proper
            records of those transactions.
          </li>
          <li>
            We may process information that you provide to us for the purpose of
            subscribing to our email notifications and/or newsletters
            ("notification data"). The notification data may be processed for
            the purposes of sending you the relevant notifications and/or
            newsletters.
          </li>
          <li>
            We may process information contained in or relating to any
            communication that you send to us ("correspondence data"). The
            correspondence data may include the communication content and
            metadata associated with the communication. Our website will
            generate the metadata associated with communications made using the
            website contact forms. The correspondence data may be processed for
            the purposes of communicating with you and record-keeping.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="4">
      <li>
        <h1> Providing your personal data to others</h1>
        <ol>
          <li>
            We may disclose your personal data to our insurers and/or
            professional advisers insofar as reasonably necessary for the
            purposes of obtaining or maintaining insurance coverage, managing
            risks, obtaining professional advice, or the establishment, exercise
            or defense of legal claims, whether in court proceedings or in an
            administrative or out-of-court procedure.
          </li>
          <li>
            Financial transactions relating to our website and services are
            handled by our payment services providers, Stripe, and PayPal. We
            will share transaction data with our payment services providers only
            to the extent necessary for the purposes of processing your
            payments, refunding such payments, and dealing with complaints and
            queries relating to such payments and refunds.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="5">
      <li>
        <h1> Retaining and deleting personal data</h1>
        <ol>
          <li>
            Section 5 sets out our data retention policies and procedure, which
            are designed to help ensure that we comply with our legal
            obligations in relation to the retention and deletion of personal
            data.
          </li>
          <li>
            Personal data that we process for any purpose or purposes shall not
            be kept for longer than is necessary for that purpose or those
            purposes.
          </li>
          <li>
            Notwithstanding the other provisions of this Section 5, we may
            retain your personal data where such retention is necessary for
            compliance with a legal obligation to which we are subject, or in
            order to protect your vital interests or the vital interests of
            another natural person.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="6">
      <li>
        <h1> Amendments</h1>
        <ol>
          <li>
            We may update this policy from time to time by publishing a new
            version on our website.
          </li>
          <li>
            You should check this page occasionally to ensure you are happy with
            any changes to this policy.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="7">
      <li>
        <h1> Your rights</h1>
        <ol>
          <li>
            In Section 7, we have summarized the rights that you have under data
            protection law. Some of the rights are complex, and not all the
            details have been included in our summaries. Accordingly, you should
            read the relevant laws and guidance from the regulatory authorities
            for a full explanation of these rights.
          </li>
          <li>
            Your principal rights under data protection law are:
            <ol>
              <li>The right to access;</li>
              <li>The right to rectification;</li>
              <li>The right to erasure;</li>
              <li>The right to restrict processing;</li>
              <li>The right to object to processing;</li>
              <li>The right to data portability;</li>
              <li>The right to complain to a supervisory authority; and</li>
              <li>The right to withdraw consent.</li>
            </ol>
          </li>
          <li>
            You have the right to confirmation as to whether we process your
            personal data and, where we do, access to the personal data,
            together with certain additional information. That additional
            information includes details of the purposes of the processing, the
            categories of personal data concerned, and the recipients of the
            personal data. Providing the rights and freedoms of others are not
            affected, we will supply you a copy of your personal data.
          </li>
          <li>
            You have the right to have any inaccurate personal data about you
            rectified and, considering the purposes of the processing, to have
            any incomplete personal data about you completed.
          </li>
          <li>
            In some circumstances, you have the right to the erasure of your
            personal data without undue delay. Those circumstances include: the
            personal data are no longer necessary in relation to the purposes
            for which they were collected or otherwise processed; you withdraw
            consent to consent-based processing; you object to the processing
            under certain rules of applicable data protection law; the
            processing is for direct marketing purposes, and the personal data
            have been unlawfully processed. However, there are exclusions of the
            right to erasure. The general exclusions include where processing is
            necessary: for exercising the right of freedom of expression and
            information; For compliance with a legal obligation; or for the
            establishment, exercise or defense of legal claims.
          </li>
          <li>
            In some circumstances, you have the right to restrict the processing
            of your personal data. Those circumstances are: you contest the
            accuracy of the personal data; processing is unlawful but you oppose
            erasure; we no longer need the personal data for the purposes of our
            processing, but you require personal data for the establishment,
            exercise or defense of legal claims; and you have objected to
            processing, pending the verification of that objection. Where
            processing has been restricted on this basis, we may continue to
            store your personal data. However, we will only otherwise process
            it: with your consent; for the establishment, exercise or defense of
            legal claims; for the protection of the rights of another natural or
            legal person; or for reasons of important public interest.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            on grounds relating to your particular situation, but only to the
            extent that the legal basis for the processing is that the
            processing is necessary for: the performance of a task carried out
            in the public interest or in the exercise of any official authority
            vested in us; or the purposes of the legitimate interests pursued by
            us or by a third party. If you make such an objection, we will cease
            to process the personal information unless we can demonstrate
            compelling legitimate grounds for the processing which override your
            interests, rights, and freedoms, or the processing is for the
            establishment, exercise or defense of legal claims.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            for direct marketing purposes (including profiling for direct
            marketing purposes). If you make such an objection, we will cease to
            process your personal data for this purpose.
          </li>
          <li>
            You have the right to object to our processing of your personal data
            for scientific or historical research purposes or statistical
            purposes on grounds relating to your particular situation unless the
            processing is necessary for the performance of a task carried out
            for reasons of public interest.
          </li>
          <li>
            To the extent that the legal basis for our processing of your
            personal data is:
            <ol>
              <li>Consent; or</li>
              <li>
                That the processing is necessary for the performance of a
                contract to which you are a party or in order to take steps at
                your request prior to entering into a contract, and such
                processing is carried out by automated means, you have the right
                to receive your personal data from us in a structured, commonly
                used and machine-readable format. However, this right does not
                apply where it would adversely affect the rights and freedoms of
                others.
              </li>
            </ol>
          </li>
          <li>
            If you consider that our processing of your personal information
            infringes data protection laws, you have a legal right to lodge a
            complaint with a supervisory authority responsible for data
            protection. You may do so in the EU member state of your habitual
            residence, your place of work, or the place of the alleged
            infringement.
          </li>
          <li>
            To the extent that the legal basis for our processing of your
            personal information is consent, you have the right to withdraw that
            consent at any time. Withdrawal will not affect the lawfulness of
            processing before the withdrawal.
          </li>
          <li>
            You may exercise any of your rights in relation to your personal
            data by written notice to us.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="8">
      <li>
        <h1> About cookies</h1>
        <ol>
          <li>
            A cookie is a file containing an identifier (a string of letters and
            numbers) that is sent by a web server to a web browser and is stored
            by the browser. The identifier is then sent back to the server each
            time the browser requests a page from the server.
          </li>
          <li>
            Cookies may be either "persistent" cookies or "session" cookies: a
            persistent cookie will be stored by a web browser and will remain
            valid until its set expiry date unless deleted by the user before
            the expiry date; a session cookie, on the other hand, will expire at
            the end of the user session, when the web browser is closed.
          </li>
          <li>
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about you
            may be linked to the information stored in and obtained from
            cookies.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="9">
      <li>
        <h1> Cookies that we use</h1>
        <ol>
          <li>
            We use cookies for the following purposes:
            <ol>
              <li>
                Authentication - We use cookies to identify you when you visit
                our website and as you navigate our website.
              </li>
              <li>
                Personalization - We use cookies to store information about your
                preferences and to personalize the website for you.
              </li>
              <li>
                Advertising - We use cookies to help us to display
                advertisements that will be relevant to you.
              </li>
              <li>
                Analysis - we use cookies to help us to analyses the use and
                performance of our website and services.
              </li>
              <li>
                Cookie consent - we use cookies to store your preferences in
                relation to the use of cookies more generally.
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="10">
      <li>
        <h1> Cookies used by our service providers</h1>
        <ol>
          <li>
            Our service providers use cookies and those cookies may be stored on
            your computer when you visit our website.
          </li>
          <li>
            We use Google Analytics to analyses the use of our website. Google
            Analytics gathers information about website use by means of cookies.
            The information gathered relating to our website is used to create
            reports about the use of our website. Google's privacy policy is
            available at <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="11">
      <li>
        <h1> Managing cookies</h1>
        <ol>
          <li>
            Most browsers allow you to refuse to accept cookies and to delete
            cookies. The methods for doing so vary from browser to browser, and
            from version to version. You can, however, obtain up-to-date
            information about blocking and deleting cookies via these links:
            <ol>
              <li><a href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a>&nbsp;(Chrome);</li>
              <li>
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>&nbsp;(Firefox);
              </li>
              <li>
                <a href="https://www.opera.com/help/tutorials/security/cookies/">https://www.opera.com/help/tutorials/security/cookies/</a>&nbsp;(Opera);
              </li>
              <li>
                <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>&nbsp;(Internet Explorer);
              </li>
              <li><a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a>&nbsp;(Safari); and</li>
              <li>
                <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a>&nbsp;(Edge).
              </li>
            </ol>
          </li>
          <li>
            Blocking all cookies will have a negative impact upon the usability
            of many websites.
          </li>
          <li>
            If you block cookies, you will not be able to use all the features
            on our website.
          </li>
        </ol>
      </li>
    </ol>
    <br/>
    <ol start="12">
      <li>
        <h1> Our details</h1>
        <ol>
          <li>This website is owned and operated by Magnus Monitors Ltd.</li>
          <li>
            We are registered in Ireland under registration number 608345, and
            our registered office is at Unit 39, Briarhill Business Park,
            Ballybrit, Galway.
          </li>
          <li>
            You can contact us:
            <ol>
              <li>By post, to the postal address given above;</li>
              <li>Using our website contact form;</li>
              <li>
                By telephone, on the contact number published on our website
                from time to time; or
              </li>
              <li>
                By email, using the email address published on our website from
                time to time.
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
  </section>
)

export default PrivacyPolicyContent
