import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Empty from "../components/empty"
import PrivacyPolicyContent from "../components/privacy.policy"
import '../styles/privacy.sass'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <PrivacyPolicyContent />
    </Layout>
  )
}

export default IndexPage
